<script>
export default {
    props: {
      empresa: { type: Object, required: true },
      oldHistoricoPadrao: { type: Object, required: true },
    },
  data() {
    return {
      titleBody: 'Alterar Cheque',
      historicoPadrao: {
        id: -1,
        descricao: null,
        status: true
      },
      cheque2: {
      
      }
    }
  },
  created() {},
  mounted() {
    this.$emit('newTitle', this.titleBody);
    this.cheque2 = this.oldHistoricoPadrao;
    this.cheque2.cheque_num= '4445';
    
  },
  methods: {
     getBancos(){
     


    },
    setPut() {
      
      this.$emit('doPut', this.cheque2);
    },
  },
}
</script>

<template>
<div class="card">
    <div class="card">
      <div class="card-body">

        <form id="form-centro-resultado" role="form" class="form-horizontal">
          <b-card no-body class="mb-1">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Numero</label>
                    <div class="col">
                      <input v-model="cheque2.cheque_num" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Agencia</label>
                    <div class="col">
                      <input v-model="cheque2.cheques_agencia" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Conta Corrente</label>
                    <div class="col">
                      <input v-model="cheque2.contacorrente_id" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id">
                    </div>
                  </div>
                </div>

              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Nome do Emitente</label>
                    <div class="col">
                      <input v-model="cheque2.cheque_emitente" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Banco</label>
                    <div class="col">
                      <multiselect v-model="cheque2.banco_id" :options="item">

                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Vencimento</label>
                    <div class="col">
                      <input v-model="cheque2.cheque_dtvencimento" id="searchCompras-fromData" type="datetime-local"
                        class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Valor</label>
                    <div class="col">
                      <input v-model="cheque2.cheque_valor" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id">
                    </div>
                  </div>
                </div>

              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Data Titulo</label>
                    <div class="col">
                      <input v-model="cheque2.cheque_dttitulo" id="searchCompras-fromData" type="datetime-local"
                        class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Data Emissão</label>
                    <div class="col">
                      <input v-model="cheque2.cheque_dtemissao" id="searchCompras-fromData" type="datetime-local"
                        class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                        <span>{{cheque2.cheque_dtemissao}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Tipo de cheque</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_tipo" id="produto-ativo1" name="ativo" value="-1">
                        Proprio
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_tipo" id="produto-ativo1" name="ativo" value="-1">
                        Terceiro
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Chque P-T</label>
                    <div class="col">
                      <input minlength="2" maxlength="2" v-model="cheque2.cheques_p_t" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Chque Conta Corrente</label>
                    <div class="col">
                      <input v-model="cheque2.cheques_contacorrente" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Chque CNPJ/CPF</label>
                    <div class="col">
                      <input v-model="cheque2.cheques_cnpjcpf" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id">
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </form>
      </div>
    </div>
    <div class="accordion" role="tablist">
      <form role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido-dados-gerais variant="light">Situação - (Clique para Visualizar)</b-button>
          </b-card-header>
          <!-- visible -- coloca esse comando no b-colapse se quiser que já fique aberto -->
          <b-collapse id="pedido-dados-gerais" accordion="pedido-accordion" role="tabpanel">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo1" name="ativo" value="-1">
                        001-Custodia de Empresa
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo2" name="ativo" value="-2"
                        unchecked-value="0">
                        003-Compensado
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo3" name="ativo" value="-3"
                        unchecked-value="0">
                        005-cancelado
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo4" name="ativo" value="-4"
                        unchecked-value="0">
                        007-Restituido
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo5" name="ativo" value="-5"
                        unchecked-value="0">
                        009-Custodia Bancaria
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo6" name="ativo" value="-6"
                        unchecked-value="0">
                        002-Depositado
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo7" name="ativo" value="-7"
                        unchecked-value="0">
                        004-Devolvido
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo8" name="ativo" value="-8"
                        unchecked-value="0">
                        006-Reapresentado
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo9" name="ativo" value="-9"
                        unchecked-value="0">
                        008-Negociado
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo10" name="ativo" value="-10"
                        unchecked-value="0">
                        010-Inegociavel
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>

          <!-- separa -->
          <!-- visible -- coloca esse comando no b-colapse se quiser que já fique aberto -->
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.valores-informados variant="light">Outras Informações - (Clique para
              Visualizar)</b-button>
          </b-card-header>
          <b-collapse id="valores-informados" accordion="valores-informados" role="tabpanel">
            <b-card-body>
              <div class="form-row form-group" role="group">
                
                <div class="col-sm-12 col-md-3" id="desc_ID">
                  <div class="form-group">
                    <label for="cartao_in_id" class="col-form-label">Codigo do Usuarios</label>
                    <div class="col">
                      <input v-model="cheque2.cod_user" id="cartao_in_id" type="text" class="form-control text-left"
                        placeholder="digite">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3" id="desc_ID">
                  <div class="form-group">
                    <label for="cheque_in_id" class="col-form-label">Ativo</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheque_status" id="produto-ativo11" name="ativo" value="-10"
                        unchecked-value="0">
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>

              </div>
              <div class="form-row form-group" role="group" id="espaco">

                <!-- vou colocar algo aqui  -->

              </div>
            </b-card-body>
          </b-collapse>
          <!-- Menus de Impostos-->


          <!--- ------------ -->
        </b-card>
        <!-- engloba  todos-->
      </form>
      <!-- form engloba todos-->
    </div>
    <!--Engloba todos -->

    <div class="card-body" id="top">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" id="butt" @click="setPut">
          Gravar
        </button>

      </div>
    </div>
  </div>
</template>