<!-- mechedo aqui agora -->
<script>
import appConfig from "@/app.config";
import { http } from "@/helpers/easyindustriaapi/config";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import Insert from "./insert";
import Edit from "./edit";

export default {
  page: {
    title: "Controle de Cheques",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, List, Insert, Edit },
  data() {
    return {
      currentHistoricoPadrao: {},
      cheques: [],
      inserindo: false,
      editando: false,
      titleBody: "Controle de Cheques",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Parâmetros",
          href: "/",
          active: true,
        },
        {
          text: "Controle Cheque",
          href: "/parametros/controle_cheque",
          active: true,
        },
      ],
      bancos_vet:[],
      agencias_vet:[]
    };
  },
  computed: {
    isHide() {
      return !this.inserindo && !this.editando;
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
  },
  mounted() {
    this.getData();
    this.get_bancos();
    this.get_Agencia();
  },
  methods: {
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    back() {
      this.inserindo = false;
      this.editando = false;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    async get_bancos() {

      try {
        let response = await http.get(
          "/banco?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.bancos_vet = response.data;
          console.log(this.cheques);

        //  this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async get_Agencia() {

      try {
        let response = await http.get(
          "/agencia?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.agencias_vet = response.data;
          console.log(this.agencias_vet);

        //  this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async getData() {
      this.onLoader();

      try {
        let response = await http.get(
          "/chequeEmitido?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.cheques = response.data;
          console.log(this.cheques);

          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    insert() {
      this.inserindo = !this.inserindo;
    },
    edit(historico) {
      console.log(historico);
      this.currentHistoricoPadrao = historico;
      this.editando = !this.editando;
    },
    async doPost(cheque) {
      cheque.empresa_id = this.currentEmpresa.id;
      cheque.user_id = this.currentUser.id;

      //console.log(cheque);

      let response = await http
        .post("/chequeEmitido?empresa_id=" + this.currentEmpresa.id, cheque)
        .catch((error) => {
          this.makeToast("danger", error.response);
          this.offLoader();
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast("success", "Registro incluído");
      }
    },
    async doPut(cheque) {
      this.onLoader();
      cheque.user_id = this.currentUser.id;
      let response = await http
        .put(
          "/chequeEmitido/" +
            cheque.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          cheque
        )
        .catch((error) => {
          this.offLoader();
          this.makeToast("danger", error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast("success", "Registro alterado");
      }
    },
    async doDelete(cheque) {
      this.onLoader();
      cheque.empresa_id = this.currentEmpresa.id;
      let response = await http
        .delete(
          "/chequeEmitido/" +
            cheque.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          cheque
        )
        .catch((error) => {
          this.makeToast("danger", error.response);
          this.offLoader();
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast("warning", "Registro excluído");
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <!-- Start Bar Buttons -->
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6"></div>
              <div class="col-sm-12 col-md-6 text-md-right">
                <button
                  @click="insert()"
                  v-if="isHide"
                  type="button"
                  class="btn btn-success"
                >
                  + Incluir Cheque
                </button>
                <button
                  @click="back()"
                  v-if="!isHide"
                  class="btn btn-secondary"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
          <!-- End Bar Buttons -->
          <div class="card-body" v-if="!isHide">
            <Insert
              v-if="inserindo"
              :empresa="currentEmpresa"
              @newTitle="changeHomeTitle"
              @doPost="doPost"
              :bancos="bancos_vet"
              :agencias="agencias_vet"
            />
            <Edit
              v-if="editando"
              :empresa="currentEmpresa"
              :oldHistoricoPadrao="currentHistoricoPadrao"
              @newTitle="changeHomeTitle"
              @doPut="doPut"
            />
          </div>
          <List
            v-if="isHide"
            :currentEmpresa="currentEmpresa"
            :listCheques="cheques"
            :hide="isHide"
            @newTitle="changeHomeTitle"
            @edit="edit"
            @doDelete="doDelete"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>